<div class="progress-container">
  <div class="progress-bar-container">
    <img src="assets/icons/battery_storage.svg"
         alt="battery state of charge"
         width="200px"
         height="140px">
    <div class="progress-bar"
    *ngIf="progressPercent != null"
         [ngClass]="progressColor"
         [style.width.%]="progressPercent - progressPercentPadding">
    </div>
  </div>
    <div class="text-center mt-8">
      Usable State of Charge
    </div>
</div>
